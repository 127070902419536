<template>
	<div :class="wrapper">
		<label v-if="this.label" :for="id" class="form-label" :class="this.labelClass">
			{{ this.label }}<sup v-if="this.required" class="text-danger">*</sup>
		</label>
		<textarea :id="id"
				  class="form-control"
				  :class="[this.class, {
						'is-valid': this.valid,
						'is-invalid': this.invalid,
				   }]"
				  :name="name"
				  ref="textarea"
				  v-model="value"
				  :placeholder="placeholder"
				  :rows="rows"
				  :disabled="disabled"
				  :readonly="readonly"
				  :required="required"
				  @input="(e) => this.$emit('inputTyped', e)"
				  @change="this.$emit('setValue', this.$refs.textarea.value)" />
		<div v-if="valid && typeof valid !== 'boolean'" class="valid-feedback" v-text="valid" />
		<div v-else-if="invalid && typeof invalid !== 'boolean'" class="invalid-feedback" v-text="invalid" />
		<div v-if="help" class="form-text" v-html="help" />
	</div>
</template>


<script>
export default {
	props: {
		inputId: {type: String},
		inputLabel: {type: String},
		inputLabelClass: {type: String},
		inputName: {type: String},
		inputValue: {type: String},
		inputClass: {type: String},
		inputWrapClass: {type: String},
		inputRows: {type: [Number, String]},
		inputHelpText: {type: String},
		inputPlaceholder: {type: String},
		inputDisabled: {type: [String, Boolean], default: false},
		inputRequired: {type: [String, Boolean], default: false},
		inputReadonly: {type: [String, Boolean], default: false},
		inputValid: {type: [String, Boolean], default: false},
		inputInValid: {type: [String, Boolean], default: false},
	},
	data() {
		return {
			id: (typeof this.inputId !== "undefined") ? this.inputId : '',
			label: (typeof this.inputLabel !== "undefined") ? this.inputLabel : '',
			labelClass: (typeof this.inputLabelClass !== "undefined") ? this.inputLabelClass : 'text-dark-gray',
			name: (typeof this.inputName !== "undefined") ? this.inputName : '',
			class: (typeof this.inputClass !== "undefined") ? this.inputClass : '',
			wrapper: (typeof this.inputWrapClass !== "undefined") ? this.inputWrapClass : '',
			rows: (typeof this.inputRows !== "undefined") ? this.inputRows : 3,
			placeholder: (typeof this.inputPlaceholder !== "undefined") ? this.inputPlaceholder : '',
			valid: (typeof this.inputValid !== "undefined") ? this.inputValid : false,
			invalid: (typeof this.inputInValid !== "undefined") ? this.inputInValid : false,
		};
	},
	methods: {
		validateInput() {
			let textarea = this.$refs.textarea;
			if (this.invalid)
				textarea.setCustomValidity(this.invalid);
			else
				textarea.setCustomValidity("");
		}
	},
	mounted() {
		this.validateInput();
	},
	watch: {
		'value': function(val, oldVal) {
			this.validateInput(val);
		}
	},
	computed: {
		value: {
			get() {
				return (typeof this.$props.inputValue !== "undefined") ? this.$props.inputValue : '';
			},
			set(value) {
				value = value.trim();
			}
		},
		disabled() {
			return this.$props.inputDisabled;
		},
		required() {
			return this.$props.inputRequired;
		},
		readonly() {
			return this.$props.inputReadonly;
		},
		help() {
			return (typeof this.$props.inputHelpText !== "undefined") ? this.$props.inputHelpText : '';
		},
		prepend() {
			return (typeof this.$props.inputPrepend !== "undefined") ? this.$props.inputPrepend : false;
		},
		append() {
			return (typeof this.$props.inputAppend !== "undefined") ? this.$props.inputAppend : false;
		},
	},
}
</script>